<template>
  <SimpleCard v-if="branch.id !== branch.slug" class="pb-3xs">
    <Stack tag="div" gap="xs" class="!w-full" direction="col">
      <Stack
        v-if="!isHomeBranch"
        tag="div"
        align="center"
        gap="2xs"
        class="h-[30px]"
        :class="{ 'ui-invisible': !isBranchStatusOpen || isBranchMerged || opensInTheFuture }"
      >
        <Switch
          v-if="selectedBranch"
          :label-visible="false"
          :id="`${branch.id}-branch-switch`"
          :model-value="branch.id === selectedBranch.id"
          :value="branch.id === selectedBranch.id"
          :name="`${branch.id}-branch-switch`"
          :label="`${branch.id}-branch-switch`"
          :disabled="branch.id === selectedBranch.id"
          @update:model-value="handleUpdateBranch(Number(branch.id))"
        />
        <Switch
          v-else
          :label-visible="false"
          :id="`${branch.id}-branch-switch`"
          :model-value="false"
          :value="false"
          :name="`${branch.id}-branch-switch`"
          :label="`${branch.id}-branch-switch`"
          @update:model-value="handleUpdateBranch(Number(branch.id))"
        />
        <Text>Select branch</Text>
      </Stack>

      <SvgLogo v-if="isHomeBranch" name="mkm-home" :width="64" :height="30" />

      <Stack tag="div" gap="2xs" direction="col" class="!w-full">
        <Stack tag="div" align="center" justify="between" class="!w-full">
          <Stack tag="div" align="center" gap="2xs" class="whitespace-nowrap overflow-hidden">
            <Icon name="pin-blank" :size="16" />
            <Text tag="p" size="md" weight="bold">{{ branch.name }}</Text>
          </Stack>

          <Text tag="p" size="caption" align="right" class="!w-fit !whitespace-nowrap"
            >{{ branch.distance }} miles</Text
          >
        </Stack>

        <Text size="caption" class="mb-3xs line-clamp-2 h-[35px]"
          >{{ branch.addresses.collection.address_line_1 }}, {{ branch.addresses.collection.address_line_2 }},
          {{ branch.addresses.collection.address_line_3 }},
          {{ branch.addresses.collection.address_line_4 }}
          {{ branch.addresses.collection.postcode }}
        </Text>

        <Text v-if="!opensInTheFuture && isBranchStatusOpen" size="caption" weight="bold" :class="openStatusStyle">{{
          isStoreOpenText
        }}</Text>

        <Text
          v-if="opensInTheFuture && branchOpeningDate"
          size="caption"
          weight="bold"
          class="text-blue-dark"
          data-test-id="branchFutureOpeningMsg"
          data-fs="branchClosedMsg"
        >
          Branch opens {{ branchOpeningDate }}
        </Text>

        <Text
          v-if="!isBranchStatusOpen && !opensInTheFuture"
          size="caption"
          weight="bold"
          class="text-red-default"
          data-test-id="branchClosedMsg"
          data-fs="branchClosedMsg"
        >
          Closed
        </Text>

        <Stack tag="div" align="center" gap="2xs">
          <Icon name="telephone" :size="16" />

          <Text size="caption" weight="bold">{{ branch.contacts.phone }}</Text>
        </Stack>

        <component
          :is="linkComponent ? linkComponent : 'a'"
          :to="`${path}${branch.slug}`"
          class="text-mkm-blue-light font-bold"
        >
          View Branch Details
        </component>
      </Stack>
    </Stack>
  </SimpleCard>
</template>

<script setup lang="ts">
import { BranchCardProps } from "../../../../packages/blueprint-ui/src/components/Card/types";
import { EXCLUDEDBRANCHES } from "~/helpers/excludedBranches";
const emit = defineEmits<{
  (event: "update:branch", value: number): void;
}>();

const props = defineProps<BranchCardProps>();

const { isStoreOpenText, openStatusStyle } = useBranchWorkingTime(props.branch);

const isHomeBranch = computed(() => props.branch.type === "home");
const isBranchStatusOpen = computed(() => props.branch.status === "open");
const isBranchMerged = computed(() => Number(props.branch.id) === EXCLUDEDBRANCHES.SCARBOROUGHCR);

const handleUpdateBranch = (value: number) => {
  emit("update:branch", value);
};
</script>
