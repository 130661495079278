<template>
  <section>
    <Stack gap="xs" align="center" class="mb-md">
      <Icon name="pin-dot" :size="16" />
      <Heading tag="h2" size="xs">Address</Heading>
    </Stack>

    <Stack v-if="branch" tag="div" gap="sm" direction="col">
      <div data-fs="branchDetailsAddress" data-test-id="branchDetailsAddress">
        <Text tag="p" size="md">{{ branchDetails.address_line_1 }}, {{ branchDetails.address_line_2 }}</Text>
        <Text tag="p" size="md" class="mb-2xs">{{ branchDetails.address_line_3 }}, {{ branchDetails.postcode }}</Text>

        <NuxtLink
          :to="branchDetails.googleMapsLink"
          class="mb-xs text-mkm-blue-light"
          aria-label="See on Google Maps"
          data-test-id="branchDetailsGetDirectionsLink"
          data-fs="branchDetailsGetDirectionsLink"
        >
          Get directions
        </NuxtLink>
      </div>

      <Stack tag="div" direction="col" gap="3xs">
        <NuxtLink
          :to="`tel:${branchDetails.phone}`"
          @click="firePhoneEvent"
          class="text-mkm-blue-light"
          aria-label="Call branch"
          data-test-id="branchDetailsPhone"
          data-fs="branchDetailsPhone"
        >
          {{ branchDetails.phone }}
        </NuxtLink>

        <NuxtLink
          :to="`mailto:${branchDetails.email}`"
          @click="fireEmailEvent"
          class="text-mkm-blue-light mb-xs"
          aria-label="Email branch"
          data-test-id="branchDetailsEmail"
          data-fs="branchDetailsEmail"
        >
          {{ branchDetails.email }}
        </NuxtLink>

        <Stack tag="div" gap="sm">
          <NuxtLink
            v-if="branchDetails.facebook"
            :to="branchDetails.facebook"
            external
            target="_blank"
            rel="noopener"
            data-test-id="branchDetailsFacebookLink"
            data-fs="branchDetailsFacebookLink"
          >
            <span class="sr-only">{{ branch[0].name }} facebook account</span>
            <SvgIcon name="facebook-colour" :width="35" :height="35" />
          </NuxtLink>

          <NuxtLink
            v-if="branchDetails.instagram"
            :to="branchDetails.instagram"
            external
            target="_blank"
            rel="noopener"
            data-test-id="branchDetailsInstagramLink"
            data-fs="branchDetailsInstagramLink"
          >
            <span class="sr-only">{{ branch[0].name }} instagram account</span>
            <SvgIcon name="instagram-colour" :width="35" :height="35" />
          </NuxtLink>
        </Stack>
      </Stack>
    </Stack>
  </section>
</template>

<script lang="ts" setup>
import type { BranchAddressProps } from "./branchDetailsTypes";
import { useCustomEvents } from "mkm-gtm";

const props = defineProps<BranchAddressProps>();

const { branch } = toRefs(props);
const { pushCustomEvent, CustomEvents } = useCustomEvents();
const { user } = useUser();
const { selectedBranch } = useBranches();

const branchDetails = computed(() => {
  if (!branch.value) {
    return {};
  }

  const { address_line_1, address_line_2, address_line_3, address_line_4, address_line_5, postcode } =
    branch?.value[0]?.addresses?.collection ?? [];

  const { phone, email } = branch?.value[0].contacts ?? {};

  const { facebook, instagram } = branch?.value[0]?.social ?? {};

  const googleMapsLink = `https://www.google.com/maps/place/${Number(branch?.value[0]?.coordinates?.lat)},${Number(
    branch?.value[0].coordinates?.lng,
  )}`;

  return {
    address_line_1,
    address_line_2,
    address_line_3,
    address_line_4,
    address_line_5,
    postcode,
    phone,
    email,
    googleMapsLink,
    facebook,
    instagram,
  };
});

const firePhoneEvent = async () => {
  const userCategory = user.value?.account.business_type.split("|");
  const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;
  await nextTick(async () => {
    await pushCustomEvent(CustomEvents.BRANCH_TELEPHONE_INTERACTION, {
      logged_in: !!user.value,
      user_id: user.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_type: user.value?.account.type ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
    });
  });
};

const fireEmailEvent = async () => {
  const userCategory = user.value?.account.business_type.split("|");
  const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;
  await nextTick(async () => {
    await pushCustomEvent(CustomEvents.BRANCH_EMAIL_ADDRESS_INTERACTION, {
      logged_in: !!user.value,
      user_id: user.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_type: user.value?.account.type ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
    });
  });
};
</script>
